<template>
     <div v-if="is_mounted" class="q-pa-lg">
        <br>
        <div align="center">
            <span class="text-h4">Rinnovo Contratto - Analisi del Rischio<br></span>
        </div>
        <br>

        <div align="center"
            v-for="(Domanda, index) in analisi_rischio"
                v-bind:key="index"
                v-bind="Domanda"
                class="col-md-5 col-12"
                >

            <fieldset>
                <span class="text-h5">{{Domanda.label}}</span>

                <QQQuestionario
                    :domande="Domanda.domande"
                    :analisi_necessita="analisi_necessita"
                    v-model="analisi_rischio"
                    @error-checked="onErrorChecked($event)"
                    @qq-questionario-change="onDomandaChanges($event)"
                />

            </fieldset>
            <br>
        </div>


        <div class="row justify-center">
            <div class="col-md-6" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked()"
                />
            </div>

            <div class="col-md-6" align="center">
                <QQButton label="AVANTI"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>
        <br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy.js";
    import { mapState, mapActions } from "vuex";
    import { mapFields } from "vuex-map-fields";
    import QQQuestionario from '@/components/QQQuestionario.vue';
    //import { mapState } from "vuex";
    //import store from "@/store";
    //import commonLib from "@/libs/commonLib.js";

    export default {
        name: "AnalisiRischio",
        components: {
            QQButton,
            QQQuestionario
        },
        data() {
            return {
                is_mounted: false,
                have_errors: false
            }
        },
        computed: {
            ...mapState({
                preventivo: state => state.formPreventivo.preventivo,
                formPreventivo: state => state.formPreventivo,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            ...mapFields('gestioneRinnovi', [
                "forza_taylormade"
            ])
        },
        methods: {
            ...mapActions({
                fetchConvertiInTaylormade: "gestioneRinnovi/fetchConvertiInTaylormade"
            }),
            onDomandaChanges() {
                this.forza_taylormade = true;
            },
            onErrorChecked(errorList) {
                let errori = false;
                errorList.forEach(errore => {
                    errori = errori || errore;
                })

                this.have_errors = errori;
            },
            onIndietroClicked() {
                this.$router.push({name : "Rinnovi.AnalisiNecessita"});
            },
        /*    impostaFormPreventivoToTaylormade() {
                var prodotto_selezionato = this.formPreventivo.preventivo.prodotti.find(p => {
                    return p.is_checked;
                });
                prodotto_selezionato.consulenza = 0;
                var proposta_attiva = prodotto_selezionato.proposte.find(p => {
                    return p.is_checked;
                });
                proposta_attiva.diritti_aby = 0;
            }, */
            async onAvantiClicked() {
                // Verifica se deve chiamare la pagina della taylormade oppure la nuova pagina con la proposta adattata al catalogo prodotti
                if (this.forza_taylormade) {
                    // Cancello prima i valori originali di consulenza e diritti
                    await this.fetchConvertiInTaylormade(this.formPreventivo);
                    // this.impostaFormPreventivoToTaylormade();
                    this.step = 5;
                    this.$router.push({name : "Preventivi.FormPreventivo"});
                    return;
                }

                // Visualizza la proposta adattata alla modifica dell'importo
                this.$router.push({name : "Rinnovi.Proposta"});
            }
        },
        async mounted() {
            this.is_mounted = false;

            this.is_mounted = true;
        }
    }
</script>
